(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/fraction-subscription/assets/javascripts/services/remove-fraction-subscription.js') >= 0) return;  svs.modules.push('/components/marketplace-data/fraction-subscription/assets/javascripts/services/remove-fraction-subscription.js');
'use strict';

const {
  jupiter
} = svs.core;
const removeFractionSubscription = (autobuyinId, callback) => {
  if (!autobuyinId) {
    return callback(new Error("Mandatory parameters, missing or invalid value, autobuyinId: ".concat(autobuyinId)));
  }
  jupiter.delete("/marketplace/1/autobuyins/".concat(autobuyinId), () => {
    callback();
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.fractionSubscription.services.removeFractionSubscription', removeFractionSubscription);

 })(window);