(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/fraction-subscription/assets/javascripts/services/update-fraction-subscription.js') >= 0) return;  svs.modules.push('/components/marketplace-data/fraction-subscription/assets/javascripts/services/update-fraction-subscription.js');
"use strict";


const {
  jupiter
} = svs.core;
const createFractionSubscription = (groupId, maxWeeklyAmount, callback) => {
  if (!groupId || !maxWeeklyAmount) {
    return callback(new Error("Mandatory parameters, missing or invalid value, groupId: ".concat(groupId, ", maxWeeklyAmount: ").concat(groupId)));
  }
  jupiter.post({
    path: "/marketplace/1/groups/".concat(groupId, "/autobuyins"),
    data: {
      maxWeeklyAmount
    }
  }, response => callback(null, response), err => callback(err.responseJSON.error));
};
const updateFractionSubscription = (autobuyinId, maxWeeklyAmount, callback) => {
  if (!autobuyinId || !maxWeeklyAmount) {
    return callback(new Error("Mandatory parameters, missing or invalid value, groupId: ".concat(autobuyinId, ", maxWeeklyAmount: ").concat(maxWeeklyAmount)));
  }
  jupiter.put({
    path: "/marketplace/1/autobuyins/".concat(autobuyinId),
    data: {
      maxWeeklyAmount
    }
  }, response => callback(null, response), err => callback(err.responseJSON.error));
};
setGlobal('svs.components.marketplaceData.fractionSubscription.services.updateFractionSubscription', updateFractionSubscription);
setGlobal('svs.components.marketplaceData.fractionSubscription.services.createFractionSubscription', createFractionSubscription);

 })(window);