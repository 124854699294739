(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/fraction-subscription/assets/javascripts/fraction-subscription-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/fraction-subscription/assets/javascripts/fraction-subscription-actions.js');
"use strict";


const {
  dialogWrapper
} = svs.components.marketplaceData;
const {
  services
} = svs.components.marketplaceData.fractionSubscription;
const {
  format
} = svs.utils;
const FRACTION_SUBSCRIPTION_SUCCESS = 'FRACTION_SUBSCRIPTION/FRACTION_SUBSCRIPTION_SUCCESS';
const FRACTION_SUBSCRIPTION_FAIL = 'FRACTION_SUBSCRIPTION/FRACTION_SUBSCRIPTION_FAIL';
const FRACTION_SUBSCRIPTION_UPDATE = 'FRACTION_SUBSCRIPTION/FRACTION_SUBSCRIPTION_UPDATE';

const onCancelSubscriptionDialogs = dispatch => {
  dispatch(dialogWrapper.actions.showDialog({
    title: "Automatiska k\xF6p har avslutats",
    icon: 'information'
  }));
};

const onChangeSubscriptionDialogs = (dispatch, newAmount) => {
  dispatch(dialogWrapper.actions.showDialog({
    title: "Automatiska k\xF6p aktiverade",
    icon: 'checkmark',
    text: "Maxbeloppet \xE4r ".concat(format.Currency(newAmount), " kr/vecka")
  }));
};
const fractionSubscriptionUpdate = function (groupId, fractionSubscriptionId, newAmount) {
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};
  return dispatch => {
    dispatch({
      type: FRACTION_SUBSCRIPTION_UPDATE,
      value: newAmount
    });
    const cb = error => {
      if (error) {
        dispatch({
          type: FRACTION_SUBSCRIPTION_FAIL,
          error
        });
        dispatch(dialogWrapper.actions.showDialog({
          title: 'Prenumerationen gick inte igenom, försök igen senare',
          type: 'error'
        }));
        callback(error);
      } else {
        dispatch({
          type: FRACTION_SUBSCRIPTION_SUCCESS,
          groupId,
          newAmount
        });
        const isCancelSubscription = newAmount === 0;
        if (isCancelSubscription) {
          onCancelSubscriptionDialogs(dispatch);
        } else {
          onChangeSubscriptionDialogs(dispatch, newAmount);
        }
        callback(null);
      }
    };
    if (fractionSubscriptionId && newAmount > 0) {
      return services.updateFractionSubscription(fractionSubscriptionId, newAmount, cb);
    } else if (fractionSubscriptionId && newAmount === 0) {
      return services.removeFractionSubscription(fractionSubscriptionId, cb);
    }
    return services.createFractionSubscription(groupId, newAmount, cb);
  };
};
setGlobal('svs.components.marketplaceData.fractionSubscription.actions', {
  fractionSubscriptionUpdate,
  FRACTION_SUBSCRIPTION_UPDATE,
  FRACTION_SUBSCRIPTION_SUCCESS,
  FRACTION_SUBSCRIPTION_FAIL
});

 })(window);